









































































































































import {Component, Prop, Vue} from "vue-property-decorator";
import markdownIt from "markdown-it"

@Component
export default class QuestionComponent extends Vue {
  @Prop()
  public onayami?: any
  @Prop()
  public fileUrls?: any[]
  @Prop()
  public isPart?: any
  @Prop()
  public mydata?: boolean
  @Prop()
  public clinic?: any
  @Prop()
  public onayamiDetail?: boolean = false

  md = new markdownIt({html: false, breaks: true})

  mounted(){
    console.log(this.$parent.$options.name)
  }
  compiledMarkdownText() {
    return this.onayami.detail ? this.md.render(this.onayami.detail) : ''
  }

  updateLesson() {
    if (this.onayami.type == 'question') {
      this.$router.push(`/qscreate/${this.$route.params.id}`)  
    } else if (this.onayami.type == 'class') {
      this.$router.push(`/onayami/create/${this.$route.params.id}`)  
    }
  }
}

