

































































































































































































































































import {Component, Prop, Watch, Vue} from "vue-property-decorator";
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import Common from '../../common/Common'
import algoliasearch from 'algoliasearch'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import VueUploadComponent from 'vue-upload-component'
import Paginate from 'vuejs-paginate'
import markdownIt from "markdown-it"
import Cropper from 'cropperjs'

@Component({
  components: {
    ClipLoader,
    VueUploadComponent,
    Paginate
  },
})
export default class PartnerComponent extends Vue {
  @Prop()
  public userInfo?: any
  @Prop()
  public tab?: any
  @Prop()
  public mydata?: any
  @Prop()
  public isPart?: any

  RATE_LOAD_COUNT = 10 //１ページの表示件数を定義
  imageEdit = false
  imageFile = []
  imageFileMessage = ''
  cropper: any = ''
  md = new markdownIt({html: false, breaks: true})
  categories: any[] = []
  isLoading = false
  rateIndex = algoliasearch(Common.ALGORIA_APP_ID, Common.ALGORIA_API_KEY).initIndex('rates')
  ratePage = 1
  ratePages = 1
  rateStep = 0
  rates: any[] = []
  rateFilter = 'all'
  ratePositionFilter = 'all'
  isRateLoading = false

  loadWithUid(uid?: string) {
    if (this.$route.query.position) {
      if (this.$route.query.position == 'clinic') {
        this.ratePositionFilter = 'clinic'
        this.rateStep = 1
      } else if (this.$route.query.position == 'patient') {
        this.ratePositionFilter = 'patient'
        this.rateStep = 2
      }
    }
    return new Promise((allResolve) => {
      let promises: any = []
      promises.push(firebase.firestore().collection('users').doc(uid ? uid : Common.getUserId()).get().then((result) => {
        let userInfo = result.data()!
        userInfo.uid = result.id
        userInfo.clinicRateIcons = Common.createRateIcon(userInfo.clinicRate)
        userInfo.studentRateIcons = Common.createRateIcon(userInfo.studentRate)
        userInfo.url = Common.createUserIconURL(userInfo.uid, userInfo.fileExist)
        if (userInfo.headerExist) {
          userInfo.headerUrl = Common.createURL(`users/${userInfo.uid}/header`)
        }
        this.$emit('update:userInfo', userInfo)
        promises.push(this.getRates(userInfo))
      }))

      promises.push(new Promise((resolve) => {
        Common.getCategory().then((categories: any) => {
          this.categories = categories
          resolve()
        })
      }))

      Promise.all(promises)
        .finally(() => {
          let skills: any = {}
          for (let key in this.userInfo.skills) {
            let category = this.categories.find((category) => {return category.value == key}).text
            skills[category] = this.userInfo.skills[key]
            for (let skey in skills[category]) {
              skills[category][skey] = {rank: skills[category][skey], rateIcons: Common.createIntegerRateIcon(skills[category][skey])}
            }
          }
          this.userInfo.skills = skills
          allResolve()
        })
    })
  }

  loadWithUserInfo(userInfo: any) {
    let promises: any = []
    promises.push(new Promise((resolve) => {
      Common.getCategory().then((categories: any) => {
        this.categories = categories
        resolve()
      })
    }))
    promises.push(this.getRates(userInfo))

    Promise.all(promises)
      .finally(() => {
        let skills: any = {}
        for (let key in userInfo.skills) {
          let category = this.categories.find((category) => {return category.value == key}).text
          skills[category] = userInfo.skills[key]
          for (let skey in skills[category]) {
            skills[category][skey] = {rank: skills[category][skey], rateIcons: Common.createIntegerRateIcon(skills[category][skey])}
          }
        }
        userInfo.skills = skills
      })
  }

  getRates(userInfo: any) {
    new Promise((resolve) => {
      this.search(userInfo).finally(() => {
        resolve()
      })
    })
  }

  search(userInfo: any, paging = false) {
    this.isRateLoading = true
    if (!paging) {
      this.ratePage = 1
    }
    return this.rateIndex.search({
        query: userInfo.uid,
        filters: this.createFilterStr(),
        hitsPerPage: this.RATE_LOAD_COUNT,
        page: this.ratePage - 1
      }).then((responses: any) => {
        this.rates = []
        responses.hits.forEach((rate: any) => {
          rate.url = Common.createUserIconURL(rate.rater, true)
          Common.imageExist(rate.url).catch(() => rate.url = '')
          this.rates.push(rate)
        })
        this.ratePages = Math.ceil(responses.nbHits / this.RATE_LOAD_COUNT)
        this.isRateLoading = false
      })
  }

  ratePageClick() {
    this.search(this.userInfo, true)
  }

  createFilterStr() {
    let filter = ''
    if (this.rateFilter != 'all') {
      filter += 'rate=' + (this.rateFilter == 'good' ? 5 : (this.rateFilter == 'normal' ? 3 : 1))
    }
    if (this.ratePositionFilter != 'all') {
      filter += filter ? ' AND ' : ''
      filter += 'isClinic=' + (this.ratePositionFilter == 'clinic' ? 1 : 0)
    }
    return filter
  }

  compiledMarkdownText() {
    return this.userInfo && this.userInfo.selfIntro ? this.md.render(this.userInfo.selfIntro) : ''
  }

  async headerImageUpload() {
    if (this.imageFile.length == 0) {
      this.imageFileMessage = 'ファイルをアップロードしてください。'
      return
    }
    this.isLoading = true
    let oldFile: any = this.imageFile[0]
    let binStr = atob(this.cropper.getCroppedCanvas().toDataURL(oldFile.type).split(',')[1])
    let arr = new Uint8Array(binStr.length)
    for (let i = 0; i < binStr.length; i++) {
      arr[i] = binStr.charCodeAt(i)
    }
    let file = new File([arr], oldFile.name, { type: oldFile.type })
    var storageRef = firebase.storage().ref()
    file = await Common.getCompressImageFileAsync(file)
    firebase.firestore().collection('users').doc(this.userInfo.uid).update({headerExist: true})
    await storageRef.child(`users/${this.userInfo.uid}/header/`).put(file)
    this.userInfo.headerExist = true
    this.userInfo.headerUrl = Common.createURL(`users/${this.userInfo.uid}/header`) + '&' + Common.createUUID()
    sessionStorage.removeItem('user')
    this.isLoading = false
    this.$bvModal.hide('header_image_modal')
  }

  inputFile(newFile: any, oldFile:any) {
    if (newFile.size >= 33554432) {
      this.imageFile = []
      this.imageFileMessage = 'ファイルの容量は32MBまでです'
      return
    }
    this.imageFileMessage = ''
    this.imageEdit = true
  }

  inputFilter(newFile: any, oldFile:any, prevent:any) {
    newFile.url = ''
    let URL = window.URL || (<any>window).webkitURL
    if (URL && URL.createObjectURL) {
      newFile.url = URL.createObjectURL(newFile.file)
    }
  }

  uploadCancel() {
    this.imageFile.forEach((value, index) => {
      this.imageFile.splice(index, 1)
    })
    this.imageEdit = false
  }

  @Watch('imageEdit')
  imageEditFunc(value: any) {
    if (value) {
      let func = () => {
        if (!this.$refs.editImage) {
          setTimeout(() => {
            func()
          }, 100)
        }
        let cropper = new Cropper((<any>this.$refs).editImage, {
          aspectRatio: 5 / 1,
          viewMode: 2,
          autoCropArea: 1
        })
        this.cropper = cropper
      }
      setTimeout(() => {
        func()
      }, 100)
    } else {
      if (this.cropper) {
        this.cropper.destroy()
        this.cropper = false
      }
    }
  }
}

