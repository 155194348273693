
































































































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import WebRTC from '../../common/WebRTC'
import Common from '../../common/Common'
import firebase from 'firebase/app'
import 'firebase/firestore'
import moment from 'moment'
require("moment-duration-format")

@Component
export default class TelComponent extends Vue {
  @Prop()
  public tab?: any
  @Prop()
  public partnerName?: string
  @Prop()
  public partnerUid?: string
  @Prop()
  public partnerWebRtcId?: string
  @Prop()
  public url?: string
  @Prop()
  public disable?: boolean
  @Prop()
  public telChatRef?: any
  @Prop()
  public isTeacher?: boolean

  startDate: any
  viewTimer: any = '00:00'
  intervalId: any
  useCamera: boolean = false
  useShareScreen: boolean = false
  btnDisable = false
  screenMax = false
  mouseTime: any
  viewButton = true

  status: string = ''
  errorMessage: string = ''
  localStreamClass: string = 'sub-stream'
  remoteStreamClass: string = 'main-stream'

  mounted() {
    WebRTC.disableTimer = this.btnDisableTimer
  }

  @Watch('partnerWebRtcId')
  calling() {
    if (this.partnerWebRtcId!.length == 0) {
      ;(<any>document.getElementById("callSound")!).pause()
    } else {
      ;(<any>document.getElementById("callSound")!).play()
    }
  }

  mouseEvent() {
    this.viewButton = true
    clearTimeout(this.mouseTime)
    this.mouseTime = setTimeout(() => {
      this.viewButton = false
    }, 3000)
  }

  isShareVideo() {
    let share = document.getElementById('remoteStream') && (<any>document.getElementById('remoteStream')).srcObject && (<any>document.getElementById('remoteStream')).srcObject.getVideoTracks().length!=0 && (<any>document.getElementById('remoteStream')).srcObject.getVideoTracks()[0].readyState == 'live'
    if ((!share && !this.useCamera)) {
      this.screenMax = false
    }
    return share
  }

  selectClass() {
    if (window.innerWidth >= 992) {
      if ((this.partnerWebRtcId!.length != 0 && this.status != 'take')) {
        if (document.getElementById("callSound")) {
          ;(<any>document.getElementById("callSound")!).play()
        }
        return 'animation-calling'
      } else {
        return 'tel-info'
      }
    }
    return 'tel-info-mobile'
  }

  showStream() {
    return (window.innerWidth < 992 && this.tab.select == -1) || window.innerWidth >= 992
  }

  call() {
    this.status = 'calling'
    ;(<any>document.getElementById("callSound")!).play()
    WebRTC.call(this.finish, this.timerStart, this.callError).then((webRtcId) => {
      (<any>this.$parent).updateRoom(false, '電話情報があります')
      firebase.firestore().collection('onayamis').doc(this.$route.params.id).collection('rooms').doc(this.$route.params.chatid).collection('chats')
        .add({
          text: '電話情報があります', 
          webRtcId: webRtcId, 
          status: 'calling', 
          createTime: firebase.firestore.FieldValue.serverTimestamp(), 
          uid: Common.getUserId(), 
          partnerUid: this.partnerUid, 
          type: 'call', read: false, push: false
          })
        .then((result) => {
          this.$emit('update:telChatRef', result)
        })
        .catch((e) => {
          this.status = ''
          this.errorMessage = '電話に失敗しました。画面を更新して再度電話をかけてください。'
        })
    })
  }

  callError() {
    // 今のところ処理なし
  }

  take() {
    ;(<any>document.getElementById("callSound")!).pause()
    WebRTC.take(this.partnerWebRtcId!, this.finish, this.takeError)
    this.timerStart()
  }

  takeError() {
    firebase.firestore().collection('onayamis').doc(this.$route.params.id).collection('rooms').doc(this.$route.params.chatid).collection('chats')
    .where('text', '==', this.partnerWebRtcId).get().then((result) => {
      result.docs.forEach((value, index) => {
        value.ref.update({text: '失敗', status: 'fail'})
      })
    })
  }

  timerStart() {
    this.status = 'take'
    this.startDate = moment()
    this.viewTimer = '00:00'
    this.intervalId = setInterval(() => {
      let diff = moment().diff(this.startDate)
      let format = diff > 3600000 ? 'hh:mm:ss' : "mm:ss"
      this.viewTimer = (<any>moment.duration(diff)).format(format, {trim: false})
    }, 1000)
  }

  btnDisableTimer() {
    this.btnDisable = true
    // streamを変更したばかりに処理を行われると落ちるので3秒待つ
    setTimeout(() => {
      this.btnDisable = false
    }, 2000)
  }

  canUseVideo() {
    return WebRTC.videoIds.length > 0
  }

  canChangeCamera() {
    return WebRTC.videoIds.length > 1
  }

  switchCamera() {
    this.useShareScreen = false
    this.useCamera = !this.useCamera
    WebRTC.switchCamera(this.useCamera, this.finish)
  }

  changeCamera() {
    WebRTC.changeCamera(this.finish)
  }

  canScreenShare() {
    return WebRTC.canScreenShare()
  }

  screenShare() {
    this.useCamera = false
    this.useShareScreen = !this.useShareScreen
    if (this.useShareScreen) {
      WebRTC.callScreenShare(this.finish)
    } else {
      WebRTC.switchCamera(this.useCamera, this.finish)
    }
  }

  isChrome() {
    return Common.isChrome() && !Common.isMobile()
  }

  finish() {
    if (this.status == 'take') {
      this.callFinish()
    } else {
      this.cancel()
    }
  }

  async cancel() {
    if(this.telChatRef.update) {
      await this.telChatRef.update({text: 'キャンセル', status: 'cancel', createTime: firebase.firestore.FieldValue.serverTimestamp()})
    }
    this.$emit('update:telChatRef', {})
    this.endProcess()
  }

  callFinish() {
    if(this.telChatRef.update) {
      this.telChatRef.update({text: this.viewTimer, status: 'finish', createTime: firebase.firestore.FieldValue.serverTimestamp()})
    }
    this.$emit('update:telChatRef', {})
    this.endProcess()
  }

  endProcess() {
    if ((<any>document.getElementById("callSound")!)) {
      ;(<any>document.getElementById("callSound")!).pause()
    }
    WebRTC.finish()
    clearInterval(this.intervalId);
    this.status = ''
    this.screenMax = false
    this.useCamera = false
    this.useShareScreen = false
    this.$emit('update:partnerWebRtcId', '')
  }

  changeStreamClass(isRemote: boolean) {
    if (isRemote) {
      this.localStreamClass = 'sub-stream'
      this.remoteStreamClass = 'main-stream'
    } else {
      this.localStreamClass = 'main-stream'
      this.remoteStreamClass = 'sub-stream2'
    }
  }

  beforeDestroy() {
    this.finish()
  }
}
