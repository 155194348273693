






































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import moment from 'moment'
import Common from '../../common/Common'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import ChatNavComponent from "@/components/chat/ChatNavComponent.vue"
import TelComponent from "@/components/chat/TelComponent.vue"
import PartnerComponent from "@/components/chat/PartnerComponent.vue"
import QuestionComponent from "@/components/chat/QuestionComponent.vue"
import ReserveComponent from "@/components/chat/ReserveComponent.vue"
import TextToUrl from "@/components/TextToUrl.tsx"
import InfiniteLoading from 'vue-infinite-loading'
import WebRTC from '../../common/WebRTC'
import Datepicker from 'vuejs-datepicker'
import {ja} from 'vuejs-datepicker/dist/locale'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'

@Component({
  filters: {
    formatDate(value: Date): string {
      return moment(value).format('HH:mm')
    },
  },
  components: {
    ClipLoader,
    InfiniteLoading,
    TelComponent,
    PartnerComponent,
    QuestionComponent,
    ReserveComponent,
    Datepicker,
    VueCtkDateTimePicker,
    TextToUrl,
  },
})
export default class Chat extends Vue {
  LOAD_COUNT = 10
  language = ja
  isLoading = true
  isWaiting = false
  isResizing = false
  isFocusTextArea = false
  hasCredit = false
  room: any = {}
  userInfo: any = {name: ''}
  onayami: any = {}
  lessonFileUrls: any[] = []
  userId: string = ''
  partnerUserInfo: any = {}
  partnerWebRtcId: string = ''
  chats: {id: string, text: string, reserveText: string, uid: string, createTime: Date, status: string, type: string, finishNum: number, read: boolean, changeReward: boolean, reward: number}[] = []
  text: string = ''
  unsubscribe: any
  reserveUnsubscribe: any
  lessonUnsubscribe:any
  roomUnsubscribe:any
  chatReserveEndUnsubscribe:any
  chatInterruptEndUnsubscribe:any
  finishEndUnsubscribe:any
  collapseIcon: string = 'chevron-up'
  tab = {select: 0}
  uploadingFileCount = 0
  alreadyReadResult: any
  reserves: any[] = [{reward:0, date: new Date(), status: 'hold', time: {startTime: '18:00', endTime: '19:00'}}]
  disabledDates = {to: new Date(), from: new Date()}
  interruptReason = ''
  completeReason = ''
  rateIndex = 0
  rate = 5
  rateComment = ''
  isClinic: boolean = false
  telChatRef: any = {}
  errorMessage = ''
  reserveErrorMessage = ''
  rateErrorMessage = ''
  visibility = true
  hasReviewReq = false
  clinicUserInfo: any = {}

  infiniteHandler($state: any) {
    if (!this.alreadyReadResult) {
      $state.complete()
      return
    }

    var chatCollection = firebase.firestore().collection('onayamis').doc(this.$route.params.id).collection('rooms').doc(this.$route.params.chatid).collection('chats').orderBy('createTime', 'desc').startAfter(this.alreadyReadResult)
    chatCollection.limit(this.LOAD_COUNT).get().then((chats) => {
      let readChats: any = []
      let images: any = []
      let imagePromises: any = []
      chats.forEach((result) => {
        let chat = result.data()
        let chatObject = {id: result.id, text: chat.text, uid: chat.uid, reserveText: chat.reserveText, createTime: chat.createTime.toDate(), status: chat.status, type: chat.type, finishNum: chat.finishNum, read: chat.read, changeReward: chat.changeReward, reward: chat.reward}
        if (chat.type == 'image') {
          imagePromises.push(new Promise((resolve, reject) => {
            firebase.storage().ref(chat.text).getDownloadURL().then((url) => {
              chatObject.text = url
              images.push(url)
              resolve()
            }).catch(() => {
              resolve()
            })
          }))
        }
        readChats.push(chatObject)
        this.updateAlreadyRead(result, chat)
        this.alreadyReadResult = result
      })
      if (chats.size == 0) {
        $state.complete()
      } else {
        Promise.all(imagePromises).then(() => {
          this.preLoadImage(images).then(() => {
            this.chats = readChats.reverse().concat(this.chats)
            $state.loaded()
            this.$nextTick(() => {
              document.getElementById(readChats[readChats.length - 1].id)!.scrollIntoView(true)
            })
          })
        })
      }
    }).catch((e) => {
      $state.loaded()
      this.errorMessage = 'チャットの読み込みに失敗しました。画面を更新してください。'
    })
  }

  resizer() {
    if (!document.getElementById('chatContents')) {
      window.onresize = () => {}
      return
    }
    this.isResizing = true
    setTimeout(() => {
      this.scrollBottom()
      this.isResizing = false
    }, 1000)
  }

  openMainRegister(){
    this.$router.push('/main-registration/' + this.$route.params.id)
    return
  }
  
  mounted() {
    if (!Common.isMobile()) {
      window.onresize = this.resizer
    }

    if (!Common.useWebRTC()) {
      this.$router.push('/unsupport')
      return
    }
    this.disabledDates.to.setDate(this.disabledDates.to.getDate() - 1)
    this.disabledDates.from.setDate(this.disabledDates.from.getDate() + 50)

    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === 'visible') {
        this.visibility = true
        this.updateAllRead()
      } else if (document.visibilityState === 'hidden') {
        this.visibility = false
      }
    })

    this.userId = Common.getUserId()
    Common.getUserInfo().then((userInfo) => {
      this.userInfo = userInfo
      if (!this.userInfo.viewChromeExtension && Common.isChrome() && !Common.isMobile()) {
        firebase.firestore().collection('users').doc(Common.getUserId()).update({viewChromeExtension: true})
        sessionStorage.removeItem('user')
      }
    })
    this.getRoomInfo()
    this.getChats()
  }


  getUserInfo(uid: string) {

  }
  getRoomInfo() {
    firebase.firestore().collection('onayamis').doc(this.$route.params.id).get().then((result) => {
      this.onayami = result.data()!
      this.onayami.id = result.id
      if (this.onayami.files) {
        this.onayami.files.forEach((file: string) => {
          firebase.storage().ref(file).getDownloadURL().then((url) => {
            let fileNames = file.split('/')
            let type = fileNames[2] == 'files' ? 'files' : 'images'
            this.lessonFileUrls.push({id: type == 'files' ? fileNames[3] : fileNames[2], url: url, type: type})
          })
        })
      }
      // 削除されたお悩み記事を参照しているときに相談室へリダイレクトさせる。
      this.lessonUnsubscribe = result.ref.onSnapshot((snapshot) => {
        if (snapshot.exists) {
          this.onayami = snapshot.data()!
          this.onayami.id = snapshot.id
        } else {
          alert('お悩み記事が削除されたため、相談室に戻ります。')
          this.$router.push('/chatlist')  
        }
      })
      //
      firebase.firestore().collection('users').doc(this.onayami.uid).get().then((user) => {
        this.clinicUserInfo = user.data()!
        this.clinicUserInfo.rateIcons = Common.createRateIcon(this.clinicUserInfo.clinicRate)
        this.clinicUserInfo.url = Common.createUserIconURL(user.id, this.clinicUserInfo.fileExist);
        if (this.clinicUserInfo.headerExist) {
            this.clinicUserInfo.headerUrl = Common.createURL(`users/${this.partnerUserInfo.uid}/header`)
        }
        (<any>this.$refs.ptcom).loadWithUserInfo(this.clinicUserInfo)
      })
    })

    let uid = Common.getUserId()
    let roomref = firebase.firestore().collection('onayamis').doc(this.$route.params.id).collection('rooms').doc(this.$route.params.chatid)

    roomref.get().then((result) => {
      this.room = result.data()
      let partnerUid = (this.room.uids[0] == uid) ? this.room.uids[1] : this.room.uids[0]
      firebase.firestore().collection('users').doc(partnerUid).get().then((userResult) => {
        this.partnerUserInfo = userResult.data()!
        this.partnerUserInfo.uid = userResult.id
        this.partnerUserInfo.clinicRateIcons = Common.createRateIcon(this.partnerUserInfo.clinicRate)
        this.partnerUserInfo.url = Common.createUserIconURL(userResult.id, this.partnerUserInfo.fileExist);
        if (this.partnerUserInfo.headerExist) {
            this.partnerUserInfo.headerUrl = Common.createURL(`users/${this.partnerUserInfo.uid}/header`)
          }
        (<any>this.$refs.ptcom).loadWithUserInfo(this.partnerUserInfo)
        this.isClinic = this.room.clinic == uid
        this.updateRoomChatNum(this.isClinic)
      })
    })
    // 評価・本登録依頼済みかどうか判定
    let ref = roomref.collection('reviewRequests')
    ref.get().then((results) =>{
      if (results.docs.length > 0) {
        this.hasReviewReq = true;
      }
    })

    this.roomUnsubscribe = roomref.onSnapshot((snapshot) => {
      if (snapshot.exists) {
        let roomData = snapshot.data()!
        this.room.status = roomData.status
        this.room.startTime = roomData.startTime
      }
    })
  }

  getChats() {
    var chatCollection = firebase.firestore().collection('onayamis').doc(this.$route.params.id).collection('rooms').doc(this.$route.params.chatid).collection('chats').orderBy('createTime', 'desc')
    chatCollection.limit(this.LOAD_COUNT).get().then((chats) => {
      let images: any = []
      let imagePromises: any = []
      let readChats: any = []
      let tenAgoDate = new Date(Date.now() - 600000).getTime()
      chats.forEach((result) => {
        let chat = result.data()
        let chatObject = {id: result.id, text: chat.text, uid: chat.uid, webRtcId : chat.WebRtcId , reserveText: chat.reserveText, createTime: chat.createTime.toDate(), status: chat.status, type: chat.type, finishNum: chat.finishNum, read: chat.read, changeReward: chat.changeReward, reward: chat.reward}
        if (chat.type == 'image') {
          imagePromises.push(new Promise((resolve, reject) => {
            firebase.storage().ref(chat.text).getDownloadURL().then((url) => {
              chatObject.text = url
              images.push(url)
              resolve()
            }).catch(() => {
              resolve()
            })
          }))
        }
        readChats.push(chatObject)
        this.updateAlreadyRead(result, chat)
        this.alreadyReadResult = result
        if (chat.type == 'call' && chat.status == 'calling') {
          readChats.pop()
          if (chat.uid != Common.getUserId() && chat.createTime.toDate().getTime() > tenAgoDate) {
            this.partnerWebRtcId = chat.webRtcId
            this.telChatRef = result.ref
            if (window.innerWidth < 992) {
              this.tab.select = -1
            } else {
              this.tab.select = 0
            }
          }
        }
      })

      Promise.all(imagePromises).then(() => {
        this.preLoadImage(images).then(() => {
          this.chats = readChats.reverse()
          // 画面表示時にすでにあるデータはこの方法でしか取れないためstatusごとにonSnapshotを行う
          let readedDate
          if (this.chats.length != 0) {
            readedDate = this.chats[this.chats.length - 1].createTime
          } else {
            readedDate = new Date()
            readedDate.setMinutes(readedDate.getMinutes() - 5)
          }
          this.unsubscribe = chatCollection.where('createTime', '>', readedDate).onSnapshot((snapshot) => {
            snapshot.docChanges().forEach((change) => {
              let data = change.doc.data()
              if (change.type === 'added') {
                if (data.type == 'call') {
                  if (data.uid != Common.getUserId()) {
                    this.partnerWebRtcId = data.webRtcId
                    if (data.status == 'cancel') {
                      this.partnerWebRtcId = ''
                    }
                    if (data.status == 'finish') {
                      this.partnerWebRtcId = ''
                    }

                    this.telChatRef = change.doc.ref
                    if (window.innerWidth < 992) {
                      this.tab.select = -1
                    } else {
                      this.tab.select = 0
                    }
                    this.updateAlreadyRead(change.doc, data)
                  }
                } else if (data.type == 'image') {
                  firebase.storage().ref(data.text).getDownloadURL().then((url) => {
                    this.preLoadImage([url]).finally(() => {
                      this.scrollBottom()
                    })
                    this.viewAddChat(change.doc, url)
                    if (this.uploadingFileCount > 0 && data.uid == Common.getUserId()) {
                      this.uploadingFileCount--
                      if (this.uploadingFileCount == 0) {
                        this.isWaiting = false
                      }
                    }
                  })
                } else {
                  if ((data.type == 'reply') && data.uid == Common.getUserId()) {
                    this.isWaiting = false
                  }
                  this.viewAddChat(change.doc)
                }
              } else if (change.type === 'modified') {
                if (data.type == 'call' && data.status != 'calling') {
                  (<any>this.$refs.tel).endProcess()
                  if (!this.chats.find((chat) => {return chat.id == change.doc.id})) {
                    this.viewAddChat(change.doc)
                    if (data.uid != Common.getUserId()) {
                      this.chats.forEach((value) => {
                        value.read = true
                      })
                    }
                  }
                } else if (data.read) {
                  this.chats.forEach((value) => {
                    value.read = true
                  })
                } else if (data.type == 'reply') {
                  if (!this.chats.find((chat) => {return chat.id == change.doc.id})) {
                    this.viewAddChat(change.doc)
                  }
                }
              }
            })
            
            this.$nextTick(() => {
              setTimeout(() => {
                this.scrollBottom()
                this.isLoading = false
              }, 500)
            })
          })
        })
      })
    })
  }

  scrollBottom() {
    let obj = document.getElementById('chatContents')!
    obj.scrollTop = obj.scrollHeight
    if (window.innerWidth < 768) {
      var obj2 = document.getElementsByTagName('html')[0]
      obj2.scrollTop = obj2.scrollHeight
    }
  }

  preLoadImage(images: Array<string>): Promise<any> {
    return new Promise((resolve, reject) => {
      if (images.length == 0) {
        resolve()
      }

      let loaded = 0
      images.forEach(element => {
        var img = new Image()
        img.src = element
        img.onload = function () {
          loaded++
          if (loaded == images.length) {
            resolve()
          }
        }
        img.onerror = function () {
          loaded++
          if (loaded == images.length) {
            resolve()
          }
        }
      })

      setTimeout(() => {
        resolve()
      }, 10000)
    })
  }

  viewAddChat(doc: any, text?: string) {
    let chat = doc.data()
    this.chats.push({
      id: doc.id, text: text ? text : chat.text, uid: chat.uid, reserveText: chat.reserveText, createTime: chat.createTime ? chat.createTime.toDate() : new Date(), 
      status: chat.status, type: chat.type, finishNum: chat.finishNum, read: chat.read, changeReward: chat.changeReward, reward: chat.reward
    })
    this.updateAlreadyRead(doc, chat)
    this.scrollBottom()
  }

  viewDate(index: number): string {
    if (index == 0) {
      return this.getDate(index, moment(this.chats[index].createTime).format('YYYY年M月D日(ddd)'))
    }
    if (moment(this.chats[index].createTime).format('YYYYMMDD') == moment(this.chats[index-1].createTime).format('YYYYMMDD')) {
      return ''
    } else {
      return this.getDate(index, moment(this.chats[index].createTime).format('YYYY年M月D日(ddd)'))
    }
  }

  getDate(index: number, date: string): string {
    let today = new Date()
    let yesterday = new Date()
    yesterday.setDate(yesterday.getDate() -1)
    if (date == moment(today).format('YYYY年M月D日(ddd)')) {
      return '今日'
    } else if (date == moment(yesterday).format('YYYY年M月D日(ddd)')) {
      return '昨日'
    }
    if (index != 0 && moment(this.chats[index].createTime).format('YYYY') == moment(this.chats[index-1].createTime).format('YYYY')) {
      return moment(this.chats[index].createTime).format('M月D日(ddd)')
    }
    return date
  }

  updateAllRead() {
    firebase.firestore().collection('onayamis')
      .doc(this.$route.params.id).collection('rooms')
      .doc(this.$route.params.chatid).collection('chats').where('uid', '==', this.partnerUserInfo.uid)
      .where('read', '==', false).get().then((datas) => {
      datas.docs.forEach((data) => {
        data.ref.update({read: true})
      })
      if (datas.size != 0) {
        this.updateRoom(true)
      }
    })
  }

  updateAlreadyRead(result: firebase.firestore.QueryDocumentSnapshot, chat: any) {
    if (this.visibility && !chat.read && chat.uid != Common.getUserId()) {
      result.ref.update({read: true})
      this.updateRoom(true)
    }
  }

  sendChat(e: KeyboardEvent) {
    this.hasReviewReq = true
    if (!e.isComposing) {
      if (!Common.isMobile()) {
        e.preventDefault()
        this.chat()
      }
    }
  }

  sendReviewAndMainRegistReq() {
    firebase.firestore().collection('onayamis').doc(this.$route.params.id).collection('rooms').doc(this.$route.params.chatid).collection('reviewRequests')
    .add({requestCount: 1 })
    .catch((e) => {
      this.errorMessage = '評価・本登録依頼に失敗しました。画面を更新して再実行してください。'
    })
  }

  chat() {
    if (this.text.trim().length != 0) {
      let text = this.text
      this.updateRoom(false, text)
      firebase.firestore().collection('onayamis').doc(this.$route.params.id).collection('rooms').doc(this.$route.params.chatid).collection('chats')
        .add({text: text, createTime: firebase.firestore.FieldValue.serverTimestamp(), uid: Common.getUserId(), partnerUid: this.partnerUserInfo.uid, type: 'text', read: false, push: false})
        .catch((e) => {
          this.errorMessage = 'チャットの投稿に失敗しました。画面を更新して再表示してください。'
        })
      setTimeout(() => {
        this.text = ''
        setTimeout(() => {
          this.auto_grow()
        }, 100)
      }, 100)
    }
    return false
  }

  updateRoomChatNum(isClinic: boolean) {
    let obj = isClinic ? {clinicChatNum: 0} : {studentChatNum: 0}
    firebase.firestore().collection('onayamis').doc(this.$route.params.id).collection('rooms').doc(this.$route.params.chatid)
      .update(obj)
  }

  updateRoom(read: boolean, text?: string) {
    let obj: any = read ? {chatNum: 0} : {chatText: text, chatCreateTime: firebase.firestore.FieldValue.serverTimestamp(), chatNum: firebase.firestore.FieldValue.increment(1), chatUid: Common.getUserId(), chatStart: true}
    obj[this.isClinic ? 'clinicChatNum' : 'studentChatNum'] = 0
    firebase.firestore().collection('onayamis').doc(this.$route.params.id).collection('rooms').doc(this.$route.params.chatid).update(obj)
  }

  clickCollapseIcon() {
    this.collapseIcon = (this.collapseIcon == 'chevron-down') ? 'chevron-up': 'chevron-down'
  }

  clickImageIcon() {
    document.getElementById("upfile")!.click()
  }

  async fileUpload(e: any) {
    this.isWaiting = true
    let files = e.target.files
    var storageRef = firebase.storage().ref();
    this.uploadingFileCount = files.length
    for (var i = 0; i < files.length; i++) {
      let file = files[i]
      file = await Common.getCompressImageFileAsync(file)
      let imageRef = storageRef.child(`images/${this.$route.params.id}/${this.$route.params.chatid}/${Common.getUserId()}/${Common.createUUID()}${file.name.replace(/.*\./g, '.')}`);
      imageRef.put(file)
      .catch((e) => {
        this.uploadingFileCount--
        this.errorMessage = '画像の投稿に失敗しました。画面を更新して再度投稿してください。'
      }).finally(() => {
        e.target.value = ''
      })
    }
  }

  async beforeDestroy() {
    ;(<any>this.$refs.tel).callFinish()
    this.unsubscribe ? this.unsubscribe() : null
    this.reserveUnsubscribe ? this.reserveUnsubscribe() : null
    this.roomUnsubscribe ? this.roomUnsubscribe() : null
    this.lessonUnsubscribe ? this.lessonUnsubscribe() : null
    this.chatReserveEndUnsubscribe ? this.chatReserveEndUnsubscribe() : null
    this.chatInterruptEndUnsubscribe ? this.chatInterruptEndUnsubscribe() : null
    this.finishEndUnsubscribe ? this.finishEndUnsubscribe() : null
  }

  textAreaFocus() {
    this.isFocusTextArea = true
    this.scrollBottom()
  }

  textAreaBlur() {
    setTimeout(() => {
      this.isFocusTextArea = false
    }, 300)
  }

  auto_grow() {
    const target: any = document.getElementById("chatText")
    target.style.height = "5px";
    target.style.height = (target.scrollHeight)+"px";
  }

  openRateModal(chat: any) {
    this.isLoading = true
    this.rate = 5
    this.rateErrorMessage = ''
    this.reserveErrorMessage = ''
    this.rateComment = 'ありがとうございました。'
    this.isLoading = false
    //this.rateIndex = chat.finishNum
    this.rateIndex = 0
    this.$bvModal.show('rate_modal')
  }

  deleteOnayami(index: number) {
    this.reserves.splice(index, 1)
  }

  registReserve(registReserves: any[]) {
    firebase.firestore().runTransaction(async transaction => {
      let uid = Common.getUserId()
      let reservesref = firebase.firestore().collection('onayamis').doc(this.$route.params.id).collection('rooms').doc(this.$route.params.chatid).collection('reserves').doc(this.isClinic ? this.partnerUserInfo.uid : uid)
      let reserveData = await transaction.get(reservesref)
      if (reserveData.exists) {
        await transaction.update(reservesref, {reserves: registReserves, updateUid: uid})
      } else {
        await transaction.set(reservesref, {reserves: registReserves, createTime: firebase.firestore.FieldValue.serverTimestamp(), uid: uid})
      }
      this.$bvModal.hide('reserve_modal')
      return Promise.resolve()
    })
  }

  createReserveRegists(callRegist: boolean = true) {
    let registReserves: any[] = []
    let i = 0
    this.reserves.forEach((value: any, index: number) => {
      if (!value.soonStart) {
        let startTimeSplit = value.time.startTime.split(':')
        value.date.setHours(startTimeSplit[0])
        value.date.setMinutes(startTimeSplit[1])
        value.date.setSeconds(0)
      }

      let endTime = null
      if (value.time.endTime) {
        endTime = new Date(value.date.getTime())
        let endTimeSplit = value.time.endTime.split(':')
        endTime.setHours(endTimeSplit[0])
        endTime.setMinutes(endTimeSplit[1])
        endTime.setSeconds(0)
        if (value.time.startTime > value.time.endTime) {
          endTime.setDate(endTime.getDate() + 1)
        }
      }
      if (value.status == 'finish' || value.status == 'interrupt') {
        status = value.status
        i++
      } else if (!callRegist) {
        status = value.status
      } else {
        status = (index == i ? 'reserve' : 'hold')
      }
      registReserves.push({reward: Number(value.reward), startTime: value.date, endTime: endTime, time: value.time, status: status, soonStart: value.soonStart ? true : false})
    })
    return registReserves
  }

  openRoom() {
    this.isWaiting = true
    firebase.firestore().collection('onayamis').doc(this.$route.params.id).collection('rooms').doc(this.$route.params.chatid)
      .update({status: 'before'}).then(() => {
        this.scrollBottom()
        this.isWaiting = false
      })
  }

  closeRoom() {
    this.isWaiting = true
    firebase.firestore().collection('onayamis').doc(this.$route.params.id).collection('rooms').doc(this.$route.params.chatid)
      .update({status: 'finish'}).then(() => {
        this.$bvModal.hide('close_modal');
        (<any>this.$refs.tel).callFinish()
        this.scrollBottom()
        this.isWaiting = false
      })
  }

  rateSend() {
    this.isWaiting = true
    let uid = Common.getUserId()

    firebase.firestore().collection('onayamis').doc(this.$route.params.id).collection('rooms').doc(this.$route.params.chatid).collection('rates')
      .doc(uid)
      .set({
        rate: Number(this.rate), 
        time: this.rateIndex, 
        comment: this.rateComment, 
        rater: uid, 
        evaluator: this.partnerUserInfo.uid, 
        isClinic: !this.isClinic, 
        title: this.onayami.title, 
        onayamiId: this.onayami.id, 
        createTime: firebase.firestore.FieldValue.serverTimestamp()});

    (<any>this.$refs.rscom).getRate()
    this.$bvModal.hide('rate_modal')
    this.isLoading = false
  }

  registReserveAndRate(registReserves:any, uid: string, rate: number) {
    let reservesref = firebase.firestore().collection('onayamis').doc(this.$route.params.id).collection('rooms').doc(this.$route.params.chatid).collection('reserves').doc(this.isClinic ? this.partnerUserInfo.uid : uid)
    reservesref.update({reserves: registReserves, updateUid: uid})
      .then(() => {
        firebase.firestore().collection('onayamis').doc(this.$route.params.id).collection('rooms').doc(this.$route.params.chatid).collection('rates')
          .add({rate: rate, time: this.rateIndex, comment: this.rateComment, rater: uid, evaluator: this.partnerUserInfo.uid, isClinic: !this.isClinic, title: this.onayami.title, onayamiId: this.onayami.id, createTime: firebase.firestore.FieldValue.serverTimestamp()})
        firebase.firestore().collection('onayamis').doc(this.$route.params.id).collection('rooms').doc(this.$route.params.chatid)
          .update({status: 'before'}).finally(() => {
            this.isWaiting = false
          });
        
        (<any>this.$refs.rscom).getRate()
        this.$bvModal.hide('rate_modal')
      })
  }

  tabSelect(tab: number, finish: boolean = false) {
    var obj = document.getElementsByTagName('html')[0]
    if (finish) {
      this.isLoading = true
      this.tab.select = tab
      setTimeout(() => {
        obj.scrollTop = obj.scrollHeight
        this.isLoading = false  
      }, 100)
    } else {
      this.tab.select = tab
      obj.scrollTop = 0
    }
  }
}
