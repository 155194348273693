





















































































































































































import {Component, Prop, Vue} from "vue-property-decorator";
import Common from '../../common/Common'
import firebase from 'firebase/app'
import 'firebase/firestore'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

@Component({
  components: {
    ClipLoader
  }
})
export default class ReserveComponent extends Vue {
  @Prop()
  public user?: any
  @Prop()
  public isPart?: any
  @Prop()
  public partner?: any
  @Prop()
  public url?: string
  @Prop()
  public isLoading?: boolean
  @Prop()
  public reserves?: any
  @Prop()
  public isClinic?: boolean
  @Prop()
  public roomStatus?: string
  @Prop()
  public onayami?: any

  rate: any = '5'
  rates: any = {}
  rateComment = ''
  selectTime: any
  isUpdate: boolean = false
  errorMessage = ''

  mounted() {
    this.getRate()
  }

  getRate() {
    this.rates = {}
    let uid = Common.getUserId()
    firebase.firestore().collection('onayamis').doc(this.$route.params.id).collection('rooms').doc(this.$route.params.chatid).collection('rates').orderBy('rate')
      .get().then((data) => {
        data.forEach((rate) => {
          let rateData = rate.data()!

          let isMyData = rateData.rater == uid
          if (this.rates[rateData.time]) {
            this.rates[rateData.time][isMyData ? 'patient' : 'clinic'] = rateData
          } else {
            let obj: any = {}
            obj[isMyData ? 'patient' : 'clinic'] = rateData
            this.rates[rateData.time] = obj
          }
        })
      })
  }

  openRateComDialog(time: number, rate: any) {
    if (rate) {
      this.rateComment = rate.comment
      this.rate = rate.rate
      this.isUpdate = true
    } else {
      this.rateComment = 'ありがとうございました。'
      this.isUpdate = false
    }
    this.selectTime = time
    this.$bvModal.show('rate_com_modal')
  }

  rateComSend() {
    this.$emit('update:isLoading', true)
    let uid = Common.getUserId()

    if (this.isUpdate) {
      firebase.firestore().collection('onayamis').doc(this.$route.params.id).collection('rooms').doc(this.$route.params.chatid).collection('rates')
        .where('time', '==', this.selectTime).where('rater', '==', uid).get().then((data) => {
          if (data.size != 1) {
            this.$emit('update:isLoading', false)
            this.errorMessage = 'データが異常です。画面を更新して再度お試しください。'
            return
          }
          data.docs[0].ref.update({rate: Number(this.rate), comment: this.rateComment, title: this.onayami.title, onayamiId: this.onayami.id}).finally(() => {
            this.$set(this.rates[this.selectTime]['patient'], 'rate', this.rate)
            this.$set(this.rates[this.selectTime]['patient'], 'comment', this.rateComment)
            this.$bvModal.hide('rate_com_modal')
            this.$emit('update:isLoading', false)
          })
        })
    } else {
      let obj: any = {rate: Number(this.rate), time: this.selectTime, comment: this.rateComment, rater: uid, evaluator: this.partner.uid, isClinic: !this.isClinic, title: this.onayami.title, onayamiId: this.onayami.id, createTime: firebase.firestore.FieldValue.serverTimestamp()}
      firebase.firestore().collection('onayamis').doc(this.$route.params.id).collection('rooms').doc(this.$route.params.chatid).collection('rates')
        .add(obj).finally(() => {
          obj.createTime = new Date()
          if (this.rates[this.selectTime]) {
            this.$set(this.rates[this.selectTime], 'patient', obj)
          } else {
            this.$set(this.rates, this.selectTime,  {'patient': obj})
          }
          this.$bvModal.hide('rate_com_modal')
          this.$emit('update:isLoading', false)
        })
    }
  }
}
